<template>
  <teleport to="#teleports">
    <Transition name="opacity">
      <div v-if="isOpen" class="popup__overlay" :style="bgColor" @click.self="closeModal">
        <div class="popup__container">
          <slot />
        </div>
      </div>
    </Transition>
  </teleport>
</template>
<script setup lang="ts">
import type {
  IPopupWrapperEmits,
  IPopupWrapperProps,
} from '~/features/battles/components/PopupWrapper/PopupWrapper.types';

const props = defineProps<IPopupWrapperProps>();
const emits = defineEmits<IPopupWrapperEmits>();

const closeModal = () => {
  emits('update:isOpen', false);
};

const bgColor = computed(() => {
  if (props.bgColor) return { '--popup-background-color': props.bgColor };
});
</script>

<style scoped lang="scss" src="./PopupWrapper.scss"></style>
