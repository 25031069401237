import type { LinearGradientConfiguration } from 'polished/lib/types/linearGradientConfiguration';

export const BattlesGradientConfigs: Record<string, LinearGradientConfiguration> = {
  MULTICAST: {
    colorStops: ['#FF0F00', '#EA9312'],
    toDirection: GlobalUtils.Converting.toDegs(88),
  },
  MULTIFIX: {
    colorStops: ['#A133F8', '#E249FF'],
    toDirection: GlobalUtils.Converting.toDegs(88),
  },
};
